
  /* .individual-listing-page {
    display: grid;
    grid-template-columns: auto-fill; 
    grid-auto-rows: minmax(100px, 1fr);
    grid-template-areas: 
        "ilp-image ilp-image ilp-image ilp-image ilp-image"
        "ilp-dialogue ilp-dialogue ilp-dialogue ilp-save-star ilp-save-star"
        "ilp-address ilp-address ilp-address ilp-save-star ilp-save-star"
        "ilp-price ilp-price ilp-price ilp-price ilp-price"
        "ilp-map ilp-map ilp-map ilp-map ilp-map";
    justify-content: center;
    gap: 15px;
    margin-top: 10px;
} */


.individual-listing-page {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

  .ilp-dialogue {
    order: 5;
    grid-area: ilp-dialogue;
    /* background-color: #04AA6D; */
  }
  
  .ilp-address {
    order: 2;
    grid-area: ilp-address;
    /* background-color: red; */
  }
  
  .ilp-image {
    order: 1;
    grid-area: ilp-image;
    background-color: whitesmoke;
    margin-left: 0px;
    width: 100%;
    height: auto;
    max-width: 384px;
    /* object-fit: fill; */
  }
  
  .ilp-price {
    order: 2;
    grid-area: ilp-price;
    /* background-color: #E9E581; */
  }
  
  .ilp-save-star {
    grid-area: ilp-save-star;
    /* background-color: cornflowerblue; */
  }
  
  .ilp-map {
    order: 4;
    grid-area: ilp-map;
    /* background-color: yellowgreen; */
    width: inherit;
  }
  
  .r-map {
    width:40vh;
    /* width: 400px; */
    height:40vh;
    margin-left: 2vh;
  }
  
  .carousel-item > div > img {
    width: 100%;
    
    height: 200px;
  }
  
  .carousel {
    max-width: inherit;
    padding: 1px;
    margin: auto;
  }
  