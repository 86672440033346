/* @import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@500&display=swap'); */

/* 
body {
  font-family: 'Inconsolata', monospace;
}


.dropbtn {
  background-color: #04AA6D;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
  visibility: visible;
}

.dropdown-content {
  display: auto;
  position: relative;
  right: 0;
  background-color: rgb(163, 182, 163);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content {
  color: green;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  visibility: visible; 
}
 
.gears{
  width:75%;
  display: inline-block;
}
.gears:first-child{
  margin-right: 3%;
}

.gears-container{
    width: 150px; 
    height: 150px;
    font-size: 24px;
    padding: 9%;
    position: relative; 
    margin: 0px auto;
}

.message{
  text-align: left;
  letter-spacing: .15em;
  padding: 40px;
  line-height: 1.5em;
  font-size: .75em;
  background: #F6F6F6; 
  height: 100%;
}
.message h1{
  margin-bottom: .25em; 
  text-transform: uppercase; 
  font-weight: bold;
  font-size: 1rem;
  border-bottom: 4px solid #DE1B1B; 
}
.gear-rotate{
	width: 2em;
	height: 2em;
  top: 50%; 
  left: 50%; 
  margin-top: -1em;
  margin-left: -1em;
	background: #E9E581;
	position: absolute;
	border-radius: 1em;
	-webkit-animation: 1s gear-rotate linear infinite;
	-moz-animation: 1s gear-rotate linear infinite;
	animation: 1s gear-rotate linear infinite;
}
.gear-rotate-left{
  margin-top: -2.2em;
  top: 50%;
  width: 2em;
	height: 2em;
	background: #E9E581;
	position: absolute;
	border-radius: 1em;
  -webkit-animation: 1s gear-rotate-left linear infinite;
  -moz-animation: 1s gear-rotate-left linear infinite;
  animation: 1s gear-rotate-left linear infinite;
}

.gear-rotate::before, .gear-rotate-left::before {
	width: 2.8em;
	height: 2.8em;
	background: 
    -webkit-linear-gradient(0deg,transparent 39%,#E9E581 39%,#E9E581 61%, transparent 61%),
    -webkit-linear-gradient(60deg,transparent 42%,#E9E581 42%,#E9E581 58%, transparent 58%),
    -webkit-linear-gradient(120deg,transparent 42%,#E9E581 42%,#E9E581 58%, transparent 58%);
	background: 
    -moz-linear-gradient(0deg,transparent 39%,#E9E581 39%,#47EC19 61%, transparent 61%),
    -moz-linear-gradient(60deg,transparent 42%,#E9E581 42%,#E9E581 58%, transparent 58%),
    -moz-linear-gradient(120deg,transparent 42%,#E9E581 42%,#E9E581 58%, transparent 58%);
	background: 
    -o-linear-gradient(0deg,transparent 39%,#E9E581 39%,#E9E581 61%, transparent 61%),
    -o-linear-gradient(60deg,transparent 42%,#E9E581 42%,#E9E581 58%, transparent 58%),
    -o-linear-gradient(120deg,transparent 42%,#47EC19 42%,#E9E581 58%, transparent 58%);
	background: -ms-linear-gradient(0deg,transparent 39%,#E9E581 39%,#E9E581 61%, transparent 61%),-ms-linear-gradient(60deg,transparent 42%,#E9E581 42%,#E9E581 58%, transparent 58%),-ms-linear-gradient(120deg,transparent 42%,#E9E581 42%,#E9E581 58%, transparent 58%);
	background: 
      linear-gradient(0deg,transparent 39%,#E9E581 39%,#E9E581 61%, transparent 61%),
    linear-gradient(60deg,transparent 42%,#E9E581 42%,#E9E581 58%, transparent 58%),
    linear-gradient(120deg,transparent 42%,#E9E581 42%,#E9E581 58%, transparent 58%);
	position: absolute;
	content:"";
	top: -.4em;
	left: -.4em;
	border-radius:1.4em;
}
.gear-rotate::after, .gear-rotate-left::after {
	width: 1em;
	height: 1em;
	background: #2B2B2B;
	position: absolute;
	content:"";
	top: .5em;
	left: .5em;
	border-radius: .5em;
}

/*
 * Keyframe Animations 
 */

@-webkit-keyframes gear-rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-180deg);
  }
}

@-moz-keyframes gear-rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-180deg);
  }
}

@keyframes gear-rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-180deg);
  }
}

@-webkit-keyframes gear-rotate-left {
  0% {
    -webkit-transform: rotate(30deg);
  }

  100% {
    -webkit-transform: rotate(210deg);
  }
}

@-moz-keyframes gear-rotate-left {
  0% {
    -webkit-transform: rotate(30deg);
  }

  100% {
    -webkit-transform: rotate(210deg);
  }
}

@keyframes gear-rotate-left {
  0% {
    -webkit-transform: rotate(30deg);
  }

  100% {
    -webkit-transform: rotate(210deg);
  }
}

.rating-box {
  font-size: 50px;
  position: relative;
  text-shadow: 0px 1px 10px rgba(0, 0, 0, 1);
  margin: auto;
  height: 55px;
  /* width: 55px; */
  top: -20px;
}

.rating-star {
  position: relative;
}

/* zoom when hover over images */
#zoom {
  /* (A) OPTIONAL DIMENSIONS */
  width: 25%;
  height: 25%;

  /* (B) ANIMATE ZOOM */
  /* ease | ease-in | ease-out | linear */
  transition: transform ease-in-out 0.3s;
}

/* (C) ZOOM ON HOVER */
#zoom:hover {
  transform: scale(2);
}

/* NAVIGATION BAR */

.app-header {
  display: flex;
  justify-content: space-between;
  height: auto;
  width: auto;
  background-color: goldenrod;
}

.home-finder-icon {
  display: flex;
  align-items: flex-start;
  width: auto;
  height: 50px;
}

.nav-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  /* align-content: space-evenly; */
  align-items: center;
  /* height: inherit; */
  background-color: goldenrod;
  border-left: 10px;
}

.home-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
  margin-top: 5px;
}

.log-in {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
  margin-top: 5px;
}

.prop {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
  margin-top: 5px;
}

.prop-icon {
  height: 15px;
  width: 15px;
}

.log-in-icon {
  height: 15px;
  width: 15px;
}

.home-link-icon {
  height: 15px;
  width: 15px;
}

.container {
  width: 50%;
  margin: 0 auto;
  padding: 15px;
  overflow: none;
}

body {
  background: url("banner-widescreen.jpg") no-repeat center fixed;
  background-size: cover;
}

.background-magnifying-glass {
  position: relative;
  background-image: url("magnifying-glass.svg");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
  background-size: 25px;
}

li {
  box-sizing: border-box;
  width: 100%;
  border: solid #f5f5f5 5px;
  padding: 5px;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  list-style-type: none;
}

li:hover {
  box-sizing: border-box;
  width: 100%;
  border: solid #f5f5f5 5px;
  padding: 5px;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  list-style-type: none;
  color: #de1b1b;
}

/* listings page */

/* 
------------------------------------------------------------
------------------------------------------------------------
------------------------------------------------------------
------------------------------------------------------------ 
*/

.item-a {
  grid-area: header;
}

.address {
  grid-area: address;
  font-size: x-small;
}

.address > p {
  margin: 0;
}

.item-c {
  grid-area: sidebar;
}

.item-d {
  grid-area: footer;
}

.image {
  grid-area: image;
  width: 100%;
}

.listings-page-house-image {
  grid-area: image;
  width: 100%;
  height: 100%;
}

.star-container {
  grid-area: star-container;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  align-content: center;
  font-size: x-small;
  width: fit-content;
  margin-top: 10px;
  margin-left: 50px;
}

.star-size {
  width: 50px;
  height: 50px;
}

.star-text {
  grid-area: star-text;
  font-size: x-small;
  width: 100px;
  height: 35px;
  margin: 0;
}

.price {
  grid-area: price;
  font-size: x-small;
}

.price > p {
  margin: 0;
}

.listing-id {
  grid-area: listing-id;
  font-size: x-small;
}

.listing-id > p {
  margin: 0;
}

.bedrooms {
  grid-area: bedrooms;
  font-size: x-small;
}

.bedrooms > p {
  margin: 0;
}

.listing-page {
  display: grid;
  grid-template-areas:
    "image image image image image image"
    "image image image image image image"
    "address address main . star-container star-container"
    "price price main . star-container star-container"
    "listing-id listing-id main . star-container star-container"
    "bedrooms bedrooms main . star-container star-container"
    "footer footer footer footer star-container star-container"
    "footer footer footer footer star-container star-container"
    "footer footer footer footer star-container star-container"
    "footer footer footer footer star-container star-container";
  background-color: goldenrod;
  border-style: solid;
  border-width: 2px;
  border-radius: 5px;
  border-color: #888;
  margin: 5px;
  box-shadow: 3px 3px 3px #777;
  width: 100%;
}

/* listings page end */

/* main page */

.center-container {
  display: flex;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  height: 100vh;
  /* Full viewport height */
  width: 100vw;
  /* Full viewport width */
}

.home-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 50%;
  width: 50%;
  /* width: 25%; */
  background-color: gold;
  flex-wrap: wrap;
}

.individual-listing-page {
  order: 3;
}

.back-to-search-page {
  order: 2;
}

.inp-box {
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  margin: 0%;
  border: 1px solid #2d2d2d;
  z-index: 5;
}

.main-btn {
  width: 100%;
  height: 100%;
  border: none;
  /* margin: 0; */
  padding: 0;
  border-radius: 5%;
  margin: 5%;
  background-color: #de1b1b;
}

.search-area {
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 5%;
}

.search-box {
  box-sizing: border-box;
  padding: 1%;
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  order: 1;
}

.button-holder {
  display: flex;
  flex-direction: row;
  justify-content:space-evenly;
  align-items: center;
  width: 100%;
  order: 2;
}

.slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: #0d6efd; */
  /* background-color: #004aba; */
  margin: 5%;
  order: 3;
}

.slider-style {
  background-color: #333333;
}

.slider-label {
  color:white;
  padding: 2px;
  text-align: center;
  background-color: #0d6efd;
}

.submit-search {
  background-color: #008cba;
  color: white;
  margin: 5%;
  order: 4;
}

.drop-down-box {
  height: 0px;
}

.range-slider__tooltip__label {
  text-align: center;
  font-size: auto;
  color: gold;
  background-color:#0d6efd;

}

.list-item {
  z-index: inherit;
  position: inherit;
  background-color: inherit;
  width: auto;
  right: 15px;
}

.drop-down-box2 {
  display: flex;
  flex-direction: column;
  z-index: 5;
  background-color: white;
  height: inherit;
  position: relative;
}

.empty {
  border: 2px dotted rgb(96 139 168);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: auto;
}

.empty > * {
  border: 2px solid rgb(96 139 168);
  border-radius: 5px;
  background-color: rgb(96 139 168 / 0.2);
  flex: 2 2 60px;
}

.listing-page-holder {
  display: flex;
  position: absolute;
  top: 100px;
  flex-direction: column;
  align-content: center;
  align-items: flex-start;
  border: 10px dotted red;
  margin-top: auto;
  width: auto;
}